import React from 'react'


function Intro() {
  return (
    <div className="intro">
      <div className="container">
        <div className="w-60 text-left pl-5">
          <h2 className="intro__subtitle">Science and Technology Research Cloud Hub</h2>
          <h1 className="intro__title">Independent Science &amp; Technology Research Cloud Service</h1>
          <div className="s-20"></div>
          <p className="intro__text w-60 text-left">The Cloud Service that is Changing the Industry with Innovative Technology</p>
        </div>
      </div>
    </div>
  )
}

export default Intro
