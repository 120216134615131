import React from 'react'
import person001 from '../assets/img/persons/person-001.jpg'
import person002 from '../assets/img/persons/person-002.jpg'
import person003 from '../assets/img/persons/person-003.jpg'
import person004 from '../assets/img/persons/person-004.jpg'
import person005 from '../assets/img/persons/person-005.jpg'
import Review from './Review'
import Title from './Title'

function Reviews() {
  return (
    <div className="section" id="reviews_block">
      <Title
        type="title"
        text="Use Cases"
      />
      <div className="container">
        <div className="reviews">
          <Review
            image={person001}
            to="left"
            align="review__right"
            name="Skunk Works"
            position="Established In: 1943"
            text="Skunkworks is the official alias for Lockheed Martin’s Advanced Development Programs or ADPs. The term “Skunkworks” is extensively used in engineering and business operations to address a small, massively autonomous firm within a bigger organization. It is responsible for the design and development of several famous Lockheed aircraft, including U-2 ( a high altitude reconnaissance aircraft), SR-71 Blackbird, F117 Nighthawk, F-22 Raptor, and F-35 Lightning II. Other advanced Skunkworks projects include a high beta fusion reactor."
          />
          <Review
            image={person002}
            to="right"
            align="review__left"
            name="Boeing Phantom Works"
            position="Boeing X-51 Waverider"
            text="Boeing Phantom Works is the highly advanced, and secretive, research arm of the Boeing Company’s defense division. It focuses on developing state-of-the-art war equipment and technologies mostly for the U.S military. The organization was founded by aerospace manufacturer, McDonnell Douglas. It came under Boeing’s control in 1996, after a merger deal."
          />
          <Review
            image={person003}
            to="left"
            align="review__right"
            name="SRI International"
            position="Established In: 1946 | Revenue: $540 million (2014)"
            text="The SRI International research institute was established by the Stanford University trustees to promote innovation as well as to support the economic development of the Menlo Park region in California. SRI focuses on areas such as material chemistry, biomedical sciences, Earth sciences, economic development, and energy studies along with computing and national defense. The institute is widely known for developing SIRI, the first virtual personal assistant. The institute performs a wide range of research and development projects for government and private agencies."
          />
          <Review
            image={person004}
            to="right"
            align="review__left"
            name="Palo Alto Research Center Incorporated"
            position="Established In: 1970"
            text="Can you imagine your life without laser printers, Ethernet, or personal computers? Surely not. And the one research lab that owes our gratitude is the legendary Palo Alto Research Center Inc. or PARC. After it was established in 1970 as an R&D division for the Xerox Corporation, it quickly became the powerhouse of the modern IT industry. Many of its inventions served as an industry standard for quite a long time. Currently, the research center performs various researches in UI design, digital manufacturing, ubiquitous computing, along with meta-materials and clean technology."
          />
          <Review
            image={person005}
            to="left"
            align="review__right"
            name="Argonne National Laboratory"
            position="Established In: 1946 | Annual Budget: $760 million"
            text="Argonne National Laboratory, situated just outside the city of Chicago, was the first National Laboratory in the United States, and the biggest in the entire Midwest region.

            The research facility was initially created to build upon Enrico Fermi’s work on nuclear reactors, and it helped the U.S Navy to get its first nuclear-powered ship.

            After the lab’s nuclear mission was ended in 1994, it shifted its primary focus on other research areas such as energy, sustainable development, renewable energy, etc."
          />
        </div>
      </div>
    </div>
  )
}

export default Reviews
