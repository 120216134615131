import React from 'react'

function Review(props) {
  return (
    <div className={props.align}>
      <img className="review__image" src={props.image} alt={props.name} />
      <div className="review__block">
        <h3 className="title case text-center w-100">{props.name}</h3>
        <blockquote>
          <div className="text-center"><cite className="subtitle">{props.position}</cite></div>
          <div className="s-40"></div>
          <p className={`text text-${props.to}`}>{props.text}</p>
        </blockquote>
      </div>
    </div>
  )
}

export default Review
