import React from 'react'
import Title from './Title'
import pic1 from '../assets/img/product/product001.jpg'
import pic2 from '../assets/img/product/product002.jpg'
import pic3 from '../assets/img/product/product003.jpg'
import pic4 from '../assets/img/product/product004.jpg'
import pic5 from '../assets/img/product/product005.jpg'
import pic6 from '../assets/img/product/product006.jpg'

function Product() {

  const slides = [
    {
      id: 1,
      pic: pic1,
      text: 'CheckLab turns your devices into a cloud phone system in 5 minutes. Add team members, measure performance &amp; boost sales with zero IT support. 6K+ customers. 100+ integrations. Pricing starts at $24/month. No setup fee. No annual contracts.',
      title: 'Tech Lab'
    },
    {
      id: 2,
      pic: pic2,
      text: 'Turns your devices into a cloud phone system in 5 minutes. Add team members, measure performance &amp; boost sales with zero IT support. 6K+ customers. 100+ integrations. Pricing starts at $24/month. No setup fee. No annual contracts.',
      title: 'AI Robotics'
    },
    {
      id: 3,
      pic: pic3,
      text: 'A shared inbox software that combines all customer communication into a single view. With built-in automation tools and integrations, the system helps share the load among team members and automate a variety of tasks, saving time and effort.',
      title: 'Scince Leads'
    },
    {
      id: 4,
      pic: pic4,
      text: 'CheckLab turns your devices into a cloud phone system in 5 minutes. Add team members, measure performance &amp; boost sales with zero IT support. 6K+ customers. 100+ integrations. Pricing starts at $24/month. No setup fee. No annual contracts.',
      title: 'IT Mix'
    },
    {
      id: 5,
      pic: pic5,
      text: 'A shared inbox software that combines all customer communication into a single view. With built-in automation tools and integrations, the system helps share the load among team members and automate a variety of tasks, saving time and effort.',
      title: 'System Helpers'
    },
    {
      id: 6,
      pic: pic6,
      text: 'A flexible cloud-based phone system for marketing, sales and support teams to improve business performance and customer satisfaction. It can be used to make, receive, record and track phone calls, texts and fax directly from CRM or Helpdesk platform.',
      title: 'Helpdesk platform'
    }
  ]

  return (
    <div className="section" id="product_block">
      <Title
        type="title"
        text="Useful Resources"
      />
      <div className="container">
        <div className="flex rowrap center">
          {slides.map(screen => (
            <div className='boxs border w-30'>
              <img
                className='pic'
                src={screen.pic}
                alt={'pic'}
              />
              <h3 className="subtitle text-center mt-5"><strong>{screen.title}</strong></h3>
              <p className="text">{screen.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Product
