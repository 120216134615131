import React from 'react'
import Title from './Title'

import icon1 from '../assets/svg/icons/f001.svg'
import icon2 from '../assets/svg/icons/f002.svg'
import icon3 from '../assets/svg/icons/f003.svg'


import fpic from '../assets/svg/feature-pic.svg'

function Features() {
  return (
    <div className="section" id="feature_block">
      <Title
        type="title"
        text="Advantages we provide of Using CheckLab Cloud"
      />
      <div className="container">
        <h2 className="subtitle w-80 ma text-center">We are a cloud-based platform for big data analysis and AI. Our services allow customers to develop, deploy, and monitor their AI models on the cloud.
        </h2>
        <div className="s-100"></div>
        <div className="flex center">
          <div className="w-50">
            <div className="box flex">
              <div className="w-20">
                <img src={icon1} alt="icon" />
              </div>
              <div className="w-80">
                <h3 className="subtitle box">Cloud data warehouse to power your data-driven innovation</h3>
                <p className="text">BigQuery is a completely serverless and cost-effective enterprise data warehouse. It has built-in machine learning and BI that works across clouds, and scales with your data.</p>
              </div>
            </div>
            <div className="box flex">
              <div className="w-20">
                <img src={icon2} alt="icon" />
              </div>
              <div className="w-80">
                <h3 className="subtitle box">Finder of laboratories by the required criterion</h3>
                <p className="text">Just as CheckLab organizes the world’s information and makes it universally accessible and useful, Looker does the same for your business data, making it easy to build insight-powered workflows and applications.</p>
              </div>
            </div>
            <div className="box flex">
              <div className="w-20">
                <img src={icon3} alt="icon" />
              </div>
              <div className="w-80">
                <h3 className="subtitle box">Object storage for companies of all sizes</h3>
                <p className="text">Cloud Storage is a managed service for storing unstructured data. Store any amount of data and retrieve it as often as you like.</p>
              </div>
            </div>
          </div>
          <div className="w-40 pl-5 ml-5">
            <img className="pl-5 ml-5" src={fpic} alt="pic" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
