import React from 'react'
import Title from './Title'
import AnchorLink from 'react-anchor-link-smooth-scroll'


function Cta() {
  return (
    <div className="section" id="cta_block">
      <Title
        type="title white"
        text="Welcome to the future of laboratory work"
      />
      <div className="container text-center">
        <div className="cta">
          <p className="text white">Checklab is a cloud computing platform that allows laboratories to work collaboratively, create predictive models and data-driven insights, and use the latest computational research infrastructure to solve challenges on a global scale.</p>
        </div>
        <AnchorLink className="btn btn--premium btn--big cta__link" href='#contact_block'>Start Now</AnchorLink>
      </div>
    </div>
  );
}

export default Cta
