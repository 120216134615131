import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import partner001 from '../assets/img/partners/partners001.png'
import partner002 from '../assets/img/partners/partners002.png'
import partner003 from '../assets/img/partners/partners003.png'
import partner004 from '../assets/img/partners/partners004.png'
import partner005 from '../assets/img/partners/partners005.png'

function Footer() {
  return (
    <footer className="footer">
      <div className="partners w-80 ma">
        <a className="partners__link" href="/">
          <img src={partner001} className="partners__image" alt="partners" />
        </a>
        <a className="partners__link" href="/">
          <img src={partner002} className="partners__image" alt="partners" />
        </a>
        <a className="partners__link" href="/">
          <img src={partner003} className="partners__image" alt="partners" />
        </a>
        <a className="partners__link" href="/">
          <img src={partner004} className="partners__image" alt="partners" />
        </a>
        <a className="partners__link" href="/">
          <img src={partner005} className="partners__image" alt="partners" />
        </a>
      </div>
      <div className="f-bottom py-5">
        <div className="container text-center">
          <div className="flex">
            <p className="text text-left white w-50"><AnchorLink className="hb-menu__link" href="#home">CheckLab</AnchorLink> - &copy;2022. All Rights Reserved</p>
            <div id="info" className="w-50 pt-3">
              <AnchorLink className="hb-menu__link" href="#home">Home</AnchorLink>
              <AnchorLink className="hb-menu__link" href="#info">Privacy Policy</AnchorLink>
              <AnchorLink className="hb-menu__link" href="#info">Terms of Use</AnchorLink>
              <AnchorLink className="hb-menu__link" href="#contact_block">Support</AnchorLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
