import React from 'react'
import Title from './Title'

function About() {
  return (
    <div className="section" id="about_block">

      <Title
        type="title"
        text="About Us"
      />
      <div className="container">
        <div className="about">
          <h2 className="subtitle w-80 ma text-center">As the world's population grows, the number of research labs continues to rise. This, in turn, leads to an increase in competition for space. Our solution, a cloud hub for research labs, will allow companies to rent out space in other countries so they can expand more quickly and cheaply.
          </h2>
        </div>
        <div className="s-40"></div>
        <div className="flex w-90 ma my-5">
          <p className="text w-40 ma">
            Research laboratories across the world spend millions of dollars every year on different devices and tools, but these tools are often incompatible with one another. As a result, institutions lose time and money when they're unable to share data. We're a cloud hub that connects research laboratories from around the world to give them access to their data through an interface that's easy-to-use, affordable, and compatible with any device.
          </p>
          <p className="text w-40 ma">They can't keep up with information overload and maintaining compliance. Our solution is a centralized cloud hub that connects research labs, collaborators, and investors in a single environment, so they can securely manage their projects and build their business. We are already working with over 20 research labs and have earned a reputation for being highly secure, scalable, and inexpensive.</p>
        </div>
      </div>
    </div>
  )
}

export default About
