import React from 'react'
import logo from '../logo.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Menu(props) {
  return (
    <div className="hb-header">
      <h1 className="hb-logo">
        <AnchorLink className="hb-logo__link scroll" href="#home">
          <img src={logo} className="hb-logo__img" alt="CheckLab" />
          <h1 className="hb-logo__text">CheckLab</h1>
        </AnchorLink>
      </h1>
      <div className={`hb-menu ${props.mobile}`}>
        <AnchorLink className="hb-menu__link" href='#about_block'>About</AnchorLink>
        <AnchorLink className="hb-menu__link" href='#product_block'>Resources</AnchorLink>
        <AnchorLink className="hb-menu__link" href='#reviews_block'>Use Cases</AnchorLink>
        <AnchorLink className="btn btn--premium" href='#contact_block'>Connect</AnchorLink>
      </div>
    </div>
  );
}

export default Menu
